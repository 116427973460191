'use client';

import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';
import React, { useState } from 'react';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

export const SigninPage: React.FC = () => {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const handleSamlLogin = async () => {
    // /api/auth/login/samlにリダイレクト
    router.push('/api/auth/login/saml');
  };

  const handleTrAuthSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const res = await signIn('trauth', {
      redirect: false,
      email,
      password,
    });

    if (res?.error) {
      setError('ログインに失敗しました。');
    } else {
      router.push('/'); // ログイン成功後のリダイレクト先
    }
  };

  return (
    <div className="flex items-center justify-center flex-col gap-12 h-screen">
      {/* {process.env.NEXT_PUBLIC_IS_TR_ENV === 'false' ? ( */}
      <Button onClick={() => handleSamlLogin()} className="max-w-[280px]">
        SAML認証でログインする
      </Button>
      <hr className="w-3/4 px-6 border-slate-100" />
      {/* ) : ( */}
      <div>
        <form onSubmit={handleTrAuthSubmit} className="flex flex-col items-center gap-4">
          <div>
            <label htmlFor="email">Email:</label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-72 text-sm font-normal"
            />
          </div>
          <div>
            <label htmlFor="password">Password:</label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-72 text-sm font-normal"
            />
          </div>
          {error && <p>{error}</p>}
          <Button type="submit" className="max-w-[280px]">
            パスワード認証でログインする
          </Button>
        </form>
      </div>
      {/* )} */}
    </div>
  );
};
