import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * widthとheightを受け取ると、cssのアスペクト比指定の文字列を返す
 * ただし、縦方向に長い場合は、正方形である 1/1 を返す
 */
export function getMovieAspectRatio(width: number | null, height: number | null) {
  if (!width || !height) {
    return `16/9`;
  }
  if (width > height) {
    return `${width}/${height}`;
  } else {
    return `1/1`;
  }
}
