import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { LoaderCircle } from 'lucide-react';
import * as React from 'react';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:opacity-80',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground m-[2px]',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2 rounded-full',
        sm: 'h-9 rounded-full px-3',
        lg: 'h-11 rounded-full px-8',
        elongated: 'rounded-full px-5 py-[0.35rem]',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  onlyStyle?: boolean; //デザインだけ出力(HydrationError対策)
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, onlyStyle = false, isLoading, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';

    if (isLoading) {
      return (
        <Comp
          className={cn(
            buttonVariants({ size, variant: 'secondary', className }),
            'disabled:opacity-80'
          )}
          disabled
          {...props}
        >
          <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
        </Comp>
      );
    }

    const Component = !onlyStyle ? (
      <Comp className={cn(buttonVariants({ size, variant, className }))} ref={ref} {...props} />
    ) : (
      <div className={cn(buttonVariants({ size, variant, className }))}>{props.children}</div>
    );
    // outlineの場合は、div側にグラデーションのBorderを付与する必要がある
    if (variant === 'outline') {
      return (
        <div className="bg-primary rounded-full inline-block">
          <Comp
            className={cn(buttonVariants({ size, variant, className }), 'outline-button')}
            ref={ref}
            {...props}
          >
            <span className="text-sm font-bold gradient-text-primary">{props.children}</span>
          </Comp>
        </div>
      );
    }
    return Component;
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
